import React from "react"
import ContactBlock from "../components/contactBlock"
import Layout from "../components/Layout"

const About = () => {
  return (
    <Layout>
      <div className="page">
        <div className="hero-block">
          <div className="headshot"></div>
          <div className="sub">
            <h2>Andrea C. Ferster</h2>
            <h3>30 Years of Excellence in Historic Preservation</h3>
          </div>
        </div>
        <div className="main-content">
          {/* About Andrea */}
          <div className="block">
            <h2>About Andrea</h2>
            <div className="block-sub">
              <p>
                The law office of Andrea Ferster focuses on litigation and
                advocacy to enforce federal environmental and historic
                preservation laws, tax exempt organizations, enforcement of
                local zoning and land use ordinances, and trail and greenway
                planning. Andrea Ferster has lectured frequently on historic
                preservation law and serves as General Counsel to the Rails to
                Trails Conservancy. She received her B.A. from Sarah Lawrence
                College in 1979 and her J.D. from the George Washington
                University National Law Center in 1984. Andrea Ferster is a
                member of the District of Columbia and New York Bars, and the
                bars of numerous federal courts.
              </p>
            </div>
          </div>

          {/* Legal representations */}
          <div className="block">
            <h2>Andrea Ferster's legal representation includes:</h2>
            <div className="block-sub">
              <ul>
                <li>
                  Litigation and advocacy to enforce federal environmental laws
                  on behalf of local governments and citizens groups involving
                  transportation projects, including projects in Alabama,
                  Connecticut, Illinois, Iowa, Louisiana, Maine, Maryland,
                  Massachusetts, Missouri, New Jersey, New Mexico, New York,
                  Ohio, Pennsylvania, the U.S. Virgin Islands, Virginia, and
                  West Virginia.{" "}
                </li>
                <li>
                  Litigation on behalf of nonprofit organizations, including the
                  National Trust for Historic Preservation, to enforce federal
                  and state historic preservation laws involving resources in
                  Arizona, California, the District of Columbia, Hawaii,
                  Maryland, Massachusetts, Louisiana, New Mexico, Ohio, and
                  Pennsylvania.
                </li>
                <li>
                  Representation of individuals, neighborhood associations, and
                  nonprofit organizations before zoning and historic
                  preservation agencies.
                </li>
                <li>
                  Litigation and advocacy to enforce federal transportation
                  policies and to defend laws that preserve railroad corridors
                  for public use.
                </li>
                <li>
                  Nonprofit organizations seeking 501(c)(3) tax exemption and
                  legal advice on corporate and tax matters.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="main-content">
          {/* Contact */}
          <ContactBlock />
        </div>
      </div>
    </Layout>
  )
}

export default About
